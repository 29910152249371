const messages = {
    sequenceTitle: "Cheetabot",
    sequenceDescription:
        "Con las secuencias de mensajes del Cheetabot podrás automatizar el contacto inicial con tus clientes, calificarlos para poner un tag y/o derivar a un ejecutivo.",
    sequencePlaceholderName: "Nombre de la secuencia",
    sequencePlaceholderMessage: "Mensaje de cierre",
    tooltipSequenceAddMessage: "Agregar mensaje de cierre",
    tooltipSequenceAddQuestionWithMessage: "Agregar pregunta con mensaje",
    tooltipSequenceAddQuestionWithOptions: "Agregar pregunta con opciones de respuesta",
    sequenceQuestionFree: "Pregunta libre",
    sequenceQuestionwWithMessageFree: "Pregunta  con respuesta libre",
    sequenceMessage: "Mensaje",
    sequenceQuestionWithOptions: "Pregunta con opciones",
    sequenceQuestionwWithOptionsAnswer: "Pregunta con opciones de respuesta",
    sequenceselectOption: "Acción para la opción {x}",
    sequenceselectButton: "Acción {n} para el botón {x}",
    sequenceOption1: "Crear nota",
    sequenceOption2: "Archivar",
    sequenceOption3: "Agregar etiqueta",
    sequenceOption4: "Mostrar sub-menú",
    sequenceOption5: "Responder con texto y/o enlace",
    sequenceOption6: "Asignar a usuario",
    sequenceOption7: "Cerrar con mensaje",
    sequenceOption8: "Asignar a grupo de ejecutivos",
    sequenceOption9: "Finalizar secuencia",
    sequenceOption10: "Guardar como nombre del cliente",
    sequenceOption11: "Guardar como  email",
    sequenceOption12: "Guardar como nombre de la empresa",
    sequenceOption13: "Cancelar orden shopify",
    sequenceOption14: "Pedir feedback",
    sequencePlaceholderQuestion: "Pregunta",
    sequencePlaceholderOption: "Opción",
    sequenceButtonPost: "Publicar",
    sequenceOptionChild1: "Enviar Link para agendar",
    sequenceOptionChild2: "Solicitar fecha y hora",
    sequenceMessageOptionChild1: "Cheetabot enviará el siguiente link para agendar la cita",
    sequenceMessageOptionChild2:
        "Cheetabot solicitará fecha y hora por mensaje, y creará una nota con los datos. Luego enviará un recordatorio al cliente ",
    sequenceMessageOptionChild3: " días antes de la cita.",
    sequencePlaceHolderLink: "Ingresa el link aquí",
    sequencePlaceHolderEndSequence: "Escribe un mensaje final o URL",
    sequencePlaceHolderFeedback: "Escribe un mensaje pidiendo feedback",
    sequenceName: "Nombre del Cheetabot",
    sequenceMessageExpectedResponseType: "Tipo de respuesta esperada",
    sequenceMessageNumber: "Numérico",
    sequenceMessageDate: "Fecha",
    sequenceMessageAlphaNumeric: "Texto libre",
    sequenceMessagePercentage: "Porcentaje",
    sequenceMessageEmailAddress: "Email",
    sequenceMessageLastName: "Apellido",
    sequenceMessageCity: "Ciudad",
    sequenceMessageDuration: "Duración",
    sequenceMessageStreetName: "Dirección",
    sequenceMessageTime: "Hora",
    sequenceMessageState: "Estado",
    sequenceMessageFirstName: "Nombre",
    sequenceMessagePhoneNumber: "Numéro de teléfono",
    sequenceMessageCountry: "País",
    sequenceMessageUpdateBot: "Última fecha de actualización",
    sequenceMessageAddMoreOption: "Agregar nueva opción",
    sequenceMessageUpdatedSuccess: "Se ha actualizado correctamente el bot",
    sequenceMessageUpdatedError: "Ha ocurrido un problema al actualizar",
    sequenceMessageSaveSuccess: "Se ha creado correctamente el bot",
    sequenceMessageSaveError: "Ha ocurrido un error al crear el bot",
    sequenceMessageDeleteSuccess: "Se ha eliminado correctamente el bot",
    sequenceMessageChatInputPlaceholder:
        "Desactiva a Cheetabot para escribir. Al hacerlo, dejará de recopilar las respuestas 👀.",
    sequenceMessageChatInputHelp1: "Mientras el Cheetabot esté activo, no puedes enviar mensajes.",
    sequenceMessageChatInputHelp2:
        "Si lo desactivas, podrás escribir nuevamente, pero Cheetabot dejará de procesar las respuestas de este cliente.",
    sequenceMessageChatInputHelp3:
        "Si luego lo vuelves a activar, Cheetabot comenzará desde 0 su proceso con este cliente.",
    sequenceMessageButtonDelete: "Borrar secuencia",
    sequenceMessageEnable: "Habilitar",
    sequenceMessageDisable: "Deshabilitar",
    sequenceMessageAlertWarningAfterUpdate:
        "Esta actualización puede tardar 1 a 3 minutos en hacer efecto, por favor espere.",
    sequenceMessageAlertWarningAfterCreate: "La creación de este bot puede tardar de 1 a 3 minutos, por favor espere.",
    sequenceMessageAlertWarningAfterDelete: "Esta acción puede tardar hasta 1 minuto, por favor espere.",
    sequenceMessageGoBack: "Volver al menú anterior",
    sequenceMessageCantUpdatedBot:
        "No es posible actualizar el bot porque existe una actualización en proceso, por favor espere al menos 3 minutos.",
    sequenceMessageButtonConfirmModal: "Confirmar",
    sequenceMessageButtonCancelModal: "Cancelar",
    sequenceMessageConfirmTitleUpdate: "Confirmar actualización de Cheetabot",
    sequenceMessageConfirmTitleDelete: "Confirmar borrado de Cheetabot",
    sequenceMessageConfirmTitleStatus: "Confirmar cambio de estado de Cheetabot",
    sequenceMessageConfirmDescription: "Esta acción va a detener todas las interacciones actuales con este Cheetabot.",
    sequenceMessageConfirmDescription2:
        "Esto significa que cualquier cliente que esté hablando con este Cheetabot no recibirá más respuestas, y cualquier flujo en curso se detendrá.",
    sequenceMessageConfirmDescription3: "¿Estás seguro de que quieres continuar?",

    sendMessageTemplate: "Enviar mensaje de inicio",
    addAction: "Agregar acción",
    removeAction: "Eliminar acción",
    uncompletedFieldsOnBot: "Tienes campos sin completar, por favor revisa e intenta nuevamente",
    botHasSavedRecently:
        "El bot se ha guardado recientemente, por favor espere 3 minutos antes de hacer modificaciones.",
    whatToDoWithAnswer: "¿Qué hacer con la respuesta?",
    chooseStartMessage: "Selecciona mensaje de inicio",
    thisTemplateExceedsTheMaximumNumberOfCharacters:
        "Este mensaje de inicio supera el máximo de caracteres permitidos.",
    sendMessageText: "Enviar mensaje de texto o URL",
    endMessageType: "Tipo de mensaje final",
    sendTemplateWithButtons: "Enviar mensaje de inicio con botones",
    endSequenceAndSendMessage: "Finalizar secuencia y enviar mensaje/URL",
    addStartMessageWithButtons: "Agregar mensaje de inicio con botones",

    cheetabots: "Cheetabots",
    cheetabotDescription:
        "Agrega mensajes, preguntas con respuesta libre y con opciones usando la botonera que está en  la parte derecha de la pantalla.",
    useThisCheetabotForNewClients: "Usar como Cheetabot de bienvenida principal.",
    cheetabotName: "Nombre del Cheetabot",
    cheetabotNamePlaceholder: "Escribe el nombre del Cheetabot",
    cheetabotNameRequired: "El nombre del Cheetabot es requerido",
    cheetabotNameMinLength: "El nombre del Cheetabot debe tener al menos 6 caracteres",
    cheetabotScriptLength: "Largo del guión",
    lastCheetabotUpdate: "Última actualización",
    createCheetaBot: "Crear Cheetabot",
    editCheetaBot: "Editar Cheetabot",
    cantEditBotNow: "No es posible editar el bot en este momento",
    utterances: "Palabras clave",
    utterancesPlaceholder: "Escribe una palabra clave",
    utterancesDescription: "Este Cheetabot se activará cuando el cliente escriba alguna de estas palabras.",
    youAlreadyHaveAWelcomingCheetabot: "Ya tienes un Cheetabot de bienvenida principal: {bot}.",
    youAlreadyHaveABotWithThisName: "Ya tienes un Cheetabot con este nombre.",
    welcomeBot: "Cheetabot de bienvenida",
    chaseBot: "Cheetabot de persecución",
    selectBotType: "Selecciona el tipo de Cheetabot",
    mainBot: "Cheetabot Principal",
    cheetabotType: "Tipo de Cheetabot",

    addMessageOrQuestion: "Agregar mensaje o pregunta",
    cheetabotPreview: "Vista previa",
    cheetabotPreviewHello: "Hola {tenant}, soy tu cliente 👋",
    cheetabotPreviewName: "Juan Perez",
    cheetabotPreviewResponse: "Respuesta del cliente",
    cheetabotPreviewLink: "https://tu-link.com",
    cheetabotPreviewEndSequenceMessage: "Mensaje de finalizar secuencia",
    utterancesDuped: "La palabra clave {utterance} ya está siendo usada por otro Cheetabot",
    youCannotUseTheBotNameAsAKeyword: "El nombre del bot no puede ser una palabra clave",
    keywordBot: "Cheetabot de palabras clave",
    welcomeBotDesc:
        "Este Cheetabot se activará cuando un cliente inicie una conversación (excepto si usa una palabra o frase clave para otro bot). Solo puedes tener un Cheetabot de bienvenida.",
    chaseBotDesc: "Este Cheetabot debe ser activado a través de una persecución.",
    keywordBotDesc:
        "Este Cheetabot se activará cuando el cliente escriba alguna de las palabras o frases clave que hayas definido.",
    chaseAndKeywordBotDesc:
        "Este Cheetabot se activará cuando el cliente escriba alguna de las palabras o frases clave que hayas definido y ademas se activará cuando un cliente inicie el bot de fidelización.",
    cheetabotPreviewKeyword: "Palabra clave",
    chaseAndKeywordBot:"Cheetabot de persecución y palabra clave",
    youNeedAWelcomeCheetabot: "Necesitas tener un cheetabot de bienvenida activo.",
    pressThePlusButtonToAddThisKeyword: "Presiona el botón + para agregar esta palabra clave.",

    noNameError: "El nombre del Cheetabot es requerido",
    noUtterancesError: "Debes agregar al menos una palabra clave",
    noQuestionsError: "Debes agregar al menos una pregunta",
    onlyClosingMessageError: "Debes agregar al menos una pregunta",
    emptyQuestionError: "Debes agregar un texto para la pregunta {question}",
    emptyOptionError: "Debes agregar un texto para la opción {question}",
    noActionSelectedError: "Debes seleccionar una acción para la pregunta {question}",
    noExpectedAnswerError: "Debes seleccionar un tipo de respuesta esperada para la pregunta {question}",
    noTagSelectedError: "Debes seleccionar una etiqueta para la pregunta {question}",
    noOptionsError: "Debes agregar al menos una opción para la pregunta {question}",
    noLinkError: "Debes agregar un link o texto para la pregunta {question}",
    noEndMessageError: "Debes agregar un mensaje final para la pregunta {question}",
    noAgentSelectedError: "Debes seleccionar un ejecutivo para la pregunta {question}",
    noAgentGroupSelectedError: "Debes seleccionar un grupo de ejecutivos para la pregunta {question}",
    emptyCloseMessageError: "Debes agregar un mensaje de cierre",
    noTemplateSelectedError: "Debes seleccionar un mensaje de inicio para la pregunta {question}",
    noFeedbackMessageError: "Debes agregar un mensaje de feedback para la pregunta {question}",
    noVariablesAllowedError: "No se permiten variables en mensajes para la pregunta {question}",

    seeCheetabotAnswers: "Ver respuestas a Cheetabot",
    cheetabotAnswers: "Respuestas a Cheetabot",
    cheetabotAnswersDescription: "Aquí puedes ver las respuestas de tus clientes a las preguntas de tu Cheetabot.",
    selectCheetabot: "Selecciona un Cheetabot",
    chooseAQuestion: "Selecciona una pregunta",
    todaysAnswers: "Respuestas de hoy",
    lastWeeksAnswers: "Respuestas de la última semana",
    lastMonthsAnswers: "Respuestas del último mes",
    backToBotsList: "Volver a la lista",
    answersChartTitle: "{rate}% tasa de respuesta ({x} respuestas) a pregunta: {question} del Cheetabot: {bot}",
    selectATimeRange: "Selecciona un rango de tiempo",

    youCheetabotIsBeingCreated: "Tu Cheetabot se está creando, tardará unos minutos en estar funcionando.",
    nonAlternativeAnswers: "Respuestas fuera de alternativa",
    thisCheetabotIsLinkedToAChase: "Este Cheetabot está vinculado a una persecución",
    deletingThisCheetabotWillDeactivateXChases: "Al borrar este Cheetabot se desactivarán las persecuciones: {chases}",
    deactivatingThisCheetabotWillDeactivateTheChases:
        "Al desactivar este Cheetabot se desactivarán las persecuciones: {chases}",
    clientAnswerExamples: "Ejemplos de respuestas",
    emptyAnswer: "<i>Respuesta vacía</i>",

    noDataFound: "No se encontraron datos.",
    tryAnotherCheetabot: "Prueba con otro rango de tiempo u otro Cheetabot.",
    tryAnotherCheetabotOrQuestion: "Prueba con otro rango de tiempo, Cheetabot o pregunta.",
    tryAnotherTimeframe: "Prueba con otro rango de tiempo.",
    anErrorOcurredObtainingCheetabotData:
        "Ocurrió un error al obtener los datos del Cheetabot. Por favor vuelve a intentarlo más tarde o selecciona otro Cheetabot.",
    youCanOnlyHaveXCheetabots: "Solo puedes tener {x} Cheetabots.",
    youWillNeedToDeleteSomeBotsToCreateANewOne: "Necesitarás borrar algunos Cheetabots para crear uno nuevo.",
}

export default messages
