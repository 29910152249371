const messages = {
    unassigned: "Sin asignar",
    myAssignments: "Mis asignados",
    all: "Todos",
    allFemale: "Todas",
    saveNumber: "Guardar número",
    conversationAssignedTo: "Conversación asignada a",
    archiveConversation: "Archivar",
    openConversation: "Abrir conversación",
    chatInputPlaceholder: "Escribe algo aquí",
    messageStartedAt: "Conversación iniciada el {date} a las {time}",
    reply: "Responder",
    resendMessage: "Reenviar mensaje",
    remindMessage: "Recuérdame esto más tarde",
    highlightMessage: "Destacar mensaje",
    deleteMessage: "Borrar mensaje",
    reminderText: "Me pediste recordarte sobre",
    assignPopoverTitle: "Selecciona un miembro de tu equipo para asignarle esta conversación.",
    selectTagPopoverTitle: "Selecciona una etiqueta para clasificar esta conversación.",
    takeNotePlaceholder: "Escribe una nota aquí",
    onlyYouCanSeeNote: "Sólo tú puedes ver esto",
    chatTemplatePlaceHolder: "Elige un mensaje de inicio para enviar",
    chatCalenderPopoverTitle: "Agenda esta conversación para más tarde",
    chatCalenderPopoverInputPlaceholder: "Ingresa una nota aquí",
    chatFilterAssignedTo: "Asignados a",
    chatFilterAssignedToAbbrv: "Asign. a",
    chatWriteNotesTitle: "Escribe notas para esta conversación",
    chatWriteNote: "Escribe una nueva nota aquí",
    archiveChatMobileTitle: "Quieres archivar esta conversación?",
    addFile: "Agregar archivo",
    fileCommentPlaceholder: "Escribe un comentario para acompañar el archivo",
    fileCommentPlaceholderDisabled:
        "No puedes enviar archivos en esta conversación, envía un mensaje de inicio primero",
    fileCommentPlaceholderMulticlientDisabled: "No puedes enviar archivos a múltiples conversaciones a la vez",
    noTemplates: "No hay mensajes de inicio para enviar, <strong>crea un mensaje de inicio</strong>",
    imgNotAvailable: "Imagen no disponible",
    fileNotAvailable: "Archivo no disponible",
    msgNotAvailable: "Mensaje no disponible",
    addToMyClients: "Agregar a mis clientes",
    addNewCustomer: "Agregar nuevo cliente",
    fileOverSizeError: "El archivo no puede pesar más de 5MB.",
    fileWrongFormatError:
        "Ese tipo de archivos no está permitido. Recomendamos usar formatos: JPG, PNG, AMR, MP4, OGG o PDF.",
    instagramFileWrongFormatError: "Instagram y Facebook sólo permiten los formatos: JPG, PNG, BMP o ICO.",
    removeFileError: "Por favor remueva el archivo.",
    fileNotReady: "El archivo no está listo, por favor vuelva a intentar en un momento.",

    workingHours: "Horario laboral",
    setWorkingHours: "Fijar horas laborales",
    workingHoursModalDescription:
        "Fija tus horas laborales para indicarle a tus contactos tu disponibilidad. Si un cliente te responde fuera de estas horas, Cheetabot va a responder con un mensaje personalizado.",
    useWorkingHours: "Usar horario laboral",
    workingHoursSelectTimeZone: "Selecciona el uso horario de tu compañía:",
    workingHoursSelectTimes: "Selecciona el horario laboral de tu compañía:",
    workingHoursAutoReplyMessage: "Escribe tu respuesta automática:",
    sameHoursAllWeek: "El horario laboral es el mismo de Lunes a Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    dayOff: "Día libre",
    copyTimeToAll: "Copiar a todos",

    messageError: "Error al enviar",
    error63016:
        "Este mensaje fue rechazado por WhatsApp por ser enviado fuera de la ventana de 24 horas.\nPor favor revisa que el último mensaje de tu cliente fue hace menos de 24 horas, o que estás enviando un Mensaje de Inicio aprobado.",
    addContact: "Agregar Contacto",
    contactAlreadyAdded: "Contacto ya agregado",
    anErrorOcurredObtainingContactInfo: "Ocurrió un error obteniendo la información de contacto compartida",
    selectedConversation: "Conversaciones seleccionadas",
    max80: "(Máximo 40)",
    theActionsYouTakeWillBeAppliedToAllTheSelectedConversations:
        "Las acciones que realice se aplicarán a todas las conversaciones seleccionadas",
    messagePopoverTagsTitle: "Selecciona una etiqueta para clasificar esta conversación.",
    messagePopoverTagsCreateTags:
        "No tienes ninguna etiqueta creada, presiona sobre el botón para crear tu primera etiqueta",

    addStage: "Crear nueva etiqueta",
    assignedToPlural: "Asignados a ",
    assignedToOthers: "Asignados a otros",
    changesMayTakeAMomentToShow: "Los cambios pueden tardar hasta 20 segundos en mostrarse tras recargar la página",
    shareYourWhatsApp: "Comparte tu WhatsApp",
    shareYourWhatsappTitle: "Comparte tu número de WhatsApp",
    shareYourWhatsappDescription:
        "Copia y haz que este enlace esté disponible en tus redes sociales y sitio web para que tus clientes se comuniquen contigo a través de WhatsApp.",
    buttonShareWhatsapp: "Lo entiendo",
    titleCreateTag: "Crear etiqueta",
    titleUpdateTag: "Actualizar etiqueta",
    placeholderNameTag: "Nombre de la etiqueta",
    selectColor: "Seleccione un color",
    buttonSave: "Guardar",
    dontForgetToShareYourWhatsAppNumber: "¡No olvides compartir tu número de WhatsApp!",
    createFastAnswer: "Crea una respuesta rápida",
    newFastAnswer: "Nueva respuesta rápida",
    notQuickText: "No hay respuestas rápidas para enviar",
    chatQuickTextPlaceHolder: "Elige una respuesta rápida para enviar en esta conversación",
    selectAnAnswer: "Selecciona una respuesta",
    seeConversationsByAssigned: "Filtrar conversaciones por usuario asignado",
    seeConversationsByAnswer: "Sólo mostrar conversaciones sin responder",
    seeConversationsByStatus: "Filtrar conversaciones por estado",
    otherFilters: "Otros filtros de conversación",
    file: "Archivo",
    camera: "Cámara",
    scheduleTo: "Agendar para ",
    halfAnHour: "30 minutos",
    pickADate: "Seleccionar una fecha",
    schedule: "Agendar",
    youHaveTimeToResponse: "Tienes {time} para responder. Escribe / para abrir respuestas rápidas.",
    youHaveTimeToResponseShortMessage: "Tienes {time} para responder",
    conversationInChaseWithQuickReply: "Enviar una respuesta rápida No va a detener esta persecución.",
    conversationInChaseWithTemplate: "Enviar un mensaje de inicio No va detener esta persecución.",
    conversationInChase: "Esta conversación actualmente está en una persecución",
    timeWindowExplanation1:
        "WhatsApp te limita a ti, como negocio, a sólo poder enviar mensajes de inicio pre-aprobados.",
    timeWindowExplanation2:
        "Si alguien te responde o envía un mensaje, se abre una ventana de 24 horas que te permite enviar mensajes libremente.",
    timeWindowExplanation3: "Esta ventana de tiempo te la mostramos en el recuadro para escribir.",
    timeWindowExplanation4:
        "Después de que esa ventana se cierre, volverás a estar limitado a enviar mensajes pre-aprobados",
    chatBlanktip1: "Envía un mensaje de inicio para comenzar esta conversación.",
    chatBlanktip2: "Si recibes un mensaje, se abrirá una ventana de 24 horas para que puedas responder con libertad.",
    thisConversationHasMovedTo: "Conversación movida a ",
    conversationsBlanktip:
        "No tienes conversaciones, para iniciar una debes crear un cliente o recibir un mensaje a {phone_number}",
    theAdminHasSetTheFollowingWorkHours: "El administrador ha designado las siguientes horas de trabajo:",
    mondayToFriday: "Lunes a Viernes",
    outsideWorkingHours: "Estás conectado fuera de horario laboral",
    inWorkingHours: "Quedan {hours} horas hábiles restantes",
    workingHoursNotSet: "No se han definido horas laborales",
    assignThisConversation: "Asignar",
    scheduleConversation: "Agendar conversación",
    conversationsSearchBlanktip:
        "No tienes conversaciones que se ajusten a tu búsqueda o filtros. Para iniciar una nueva conversación debes crear un cliente o recibir un mensaje a {phone_number}",
    newMessageNotification: "Has recibido un mensaje de ",
    newClientNotification: "Has recibido un mensaje de un nuevo cliente",
    conversationsWithBotCantBeMultiselected: "Conversaciones con bot activo no pueden ser seleccionadas",
    disabledChatMsg:
        "Tu cuenta ha sido desactivada debido a problemas de pago o porque aún no has definido un plan de suscripción.",
    disabledChatMsgPlan: "¡Necesitas configurar tu suscripción antes de enviar mensajes!",
    disabledChatMsgPlanBtn: "Click aquí para configurar tu suscripción",
    disabledChatMsgPhoneNumber: "¡Necesitas conectar con alguna plataforma de mensajería!",
    disabledChatMsgPhoneNumberBtn: "Click aquí para conectar con una plataforma",
    activateNotifications: "Activar notificaciones",
    disableNotifications: "Desactivar notificaciones",
    soundFileError: "Ocurrió un error con el archivo de sonido.",
    anErrorOcurredSelectingFile:
        "Ocurrió un error: no seleccionaste un archivo o el archivo selecto tiene problemas, por favor intenta con otro archivo.",
    sendingFiles: "Enviando archivos...",
    anErrorOcurredSendingAFile:
        "Ocurrió un error enviando un archivo, por favor vuelve a intentarlo o cambia el archivo.",
    thisConversationsHasBeenAssignedTo: "Esta conversación fue asignada a ",
    thisConversationHasCheetabotActive: "Esta conversación está siendo atendida por Cheetabot",
    thisConversationHasBlockActive: "Esta conversación se ignorará al usar persecuciones de segmentos.",
    thisConversationHasAChaseActuve: "Esta conversación tiene una persecución activa.",
    thisConversationHasAShopifyChaseActuve: "Esta conversación tiene una persecución con Shopify activa.",
    automaticAgentAssignmentIsActive: "La asignación automática de ejecutivos está activa.",
    lastAssignedAgentWas: "El último ejecutivo asignado fue ",
    chatStatusUnreadDesc: "Hay mensajes del cliente no leídos por el ejecutivo asignado.",
    chatStatusPendingDesc: "El último mensaje fue envíado por el cliente.",
    chatStatusAnsweredDesc: "Conversación respondida.",
    whatsAppShareDesc:
        "Además puedes compartir tu WhatsApp Cheetrack en Facebook, para ello debes seguir las instrucciones del siguiente link https://www.facebook.com/[nombre de tu empresa en facebook]/settings/?tab=whatsapp_management. Reemplazando en el link [nombre de tu empresa en facebook] por el nombre de tu empresa tal como se ve en Facebook",
    onlyTheAdminCanSetWorkingHours: "Sólo el administrador puede definir las horas laborales de esta compañía.",
    dialogConfirmDeleteTagDescription1:
        "Esta etiqueta está asociada a {{entities}}, primero debes eliminar esta funcionalidad o desligar la etiqueta de ésta antes de poder eliminar esta etiqueta",
    dialogConfirmDeleteTagDescription2:
        "Esta etiqueta está asignada a {{number_clients}} clientes, al ser eliminada se desasignará de estas conversaciones ¿Estás seguro que quieres continuar?",
    dialogConfirmDeleteTagTitle: "¿Estás seguro de eliminar este tag?",
    hasChaseActive: "En persecución",
    hasBotActive: "Con Cheetabot",
    lookForAStartMessageToBeginThisConversation: "Busca un mensaje de inicio para enviar.",
    clickOAStartMessageToSelectIt: "Haz click en un mensaje de inicio para seleccionarlo.",
    sendMessage: "Enviar mensaje",
    noTemplatesForSearch:
        "No tienes mensajes de inicio que se ajusten a tu búsqueda, <strong>haz click aquí si necesitas crear uno nuevo</strong>.",
    clickOAQuickTextToSelectIt: "Haz click en una respuesta rápida para seleccionarla.",
    noQuickTextsForSearch:
        "No tienes respuestas rápidas que se ajusten a tu búsqueda, <strong>haz click aquí si necesitas crear una nueva</strong>.",
    noQuickTexts:
        "No tienes respuestas rápidas creadas, <strong>haz click aquí para ir a crear respuestas rápidas</strong>.",
    error63018: "Tu número de WhatsApp ha excedido el límite de conversaciones diarias impuesto por WhatsApp.",
    error63038:
        "Tu número de WhatsApp ha excedido el límite de mensajes que pueden ser envíados cada 24 horas impuesto por WhatsApp.",
    whatsAppLimitLink: "Para más información ve la Política de limitaciones de WhatsApp.",
    messageLimitWarning: "¡Estás acercándote al límite de conversaciones diario!",
    messageLimitReached: "¡Has llegado al límite de conversaciones diario!",
    messageLimitDesc:
        "WhatsApp impone un límite de 1000 conversaciones activas al día, si llegas al límite no podrás iniciar conversaciones nuevas hasta el día siguiente.",
    audioFormatIncompatible:
        "Este navegador no es compatible con el formato de audio, por favor intenta con otro navegador.",
    instagramClient: "Cliente de Instagram",
    conversationSource: "Origen",
    instagramTimeWindowExp1:
        "Facebook e Instagram API nos limitan, como proveedores de mensajería, a sólo poder responder a mensajes.",
    instagramTimeWindowExp2:
        "Esto significa que no puedes iniciar una conversación con una cuenta de Instagram o Facebook. Y sólo si alguien te envía un mensaje directo, se abre una ventana de 24 horas para responder con libertad.",
    instagramTimeWindowExp3: "Esta ventana de tiempo te la mostramos en el recuadro para escribir.",
    instagramTimeWindowExp4:
        "Después de que esa ventana cierre, no podrás enviar mensajes a esa cuenta de Facebook o Instagram hasta que vuelvan a enviarte mensajes a ti.",
    youNeedToWaitForThisClientToWriteFirst:
        "Sólo puedes enviar mensajes hasta 24 horas después de que este cliente te escriba.",
    youCantSendMultiMsgToInstagramClients:
        "No puedes enviar mensajes a múltiples clientes de Facebook o Instagram a la vez.",
    instagramStoryMention: "Ver historia",
    instagramStoryReply: "Respuesta en historia",
    storyContentNotAvailable: "El contenido de esta historia ya no está disponible.",
    instagramStoryMentionText: " te ha mencionado en una historia.",
    thisClient: "Este cliente",
    thisConversationIsNotAssigned: "Esta conversación no está asignada a ningún usuario.",
    thisConversationIsAssignedTo: "Esta conversación está asignada a ",
    youWantToSendYourMessageAnyway: "¿Quieres enviar el mensaje de todas maneras?",
    assignThisConversationToYou: "Click aquí para asignarte esta conversación.",
    sendMessageAnyway: "Enviar mensaje",
    hasNotReadTheLastMessage: " no ha leído el último mensaje.",
    markAsRead: "Marcar como leído",
    hasReadAllMessages: " ha leído todos los mensajes.",
    markAsUnread: "Marcar como no leído",
    youHaveReadAllMessages: "Has leído todos los mensajes.",
    allMessagesHaveBeenRead: "Se han leído todos los mensajes.",
    youHaveSentTheSameMessageXTimes:
        "Has envíado el mismo mensaje {x} veces seguidas, ¿te gustaría crearlo como una respuesta rápida?",
    clickToMarkAsRead: "Click para marcar como leído",
    clickToMarkAsUnread: "Click para marcar como no leído",
    pauseChase: "Pausar la persecución activa de esta conversación",
    resumeChase: "Reanudar la persecución de esta conversación",
    thisConversationHasAPausedChase: "Esta conversación tiene una persecución pausada.",
    pause: "Pausar",
    resume: "Reanudar",
    sendYourShopifyProducts: "Envía tus productos de Shopify a tus clientes",
    chooseAShopifyProductToSend: "Selecciona un producto de Shopify a enviar:",
    sendProductAsMsg: "Enviar mensaje con este producto",
    sendACheckoutLink: "Enviar como carro de compra",
    textMessage: "Mensaje de Texto",
    typeToOpenQuickReplies: "Escribe / para abrir respuestas rápidas.",
    SMSExplanation1:
        "Un SMS tiene un tamaño de contenido que depende de su largo y si haces uso de símbolos, urls, emojis, etc.",
    SMSExplanation2: "Dependiendo en el tamaño de contenido del SMS, será dividido en segmentos.",
    SMSExplanation3: "Cada segmento será enviado por separado y cobrado como un SMS individual.",
    SMSExplanation4: "Adicionalmente, el SMS completo no puede tener más de 320 carácteres.",
    thisMessageWillBeSentAsXSegments: "Este mensaje será envíado en {x} segmentos.",
    error21610: "Este cliente se ha desuscrito de tus mensajes SMS.",
    youHaveReachedTheBottom:
        "No se han encontrado más conversaciones. Revisa tus filtros o búsqueda si necesitas ver otras.",

    thisMessageExceedsTheCharacterLimit:
        "Este mensaje excede el límite de carácteres. ¿Quieres enviarlo en varios mensajes?",
    good: "Buena",
    bad : "Mala",
    newClient: "Nuevo Cliente",
    lastExpGood: "Últ Exp.: Buena",
    lastExpBad: "Últ. Exp.: Mala",
    daysLastVisit:"{days} días sin venir",
    tableSector:"Mesa-sector",
    numberVisits: "{number_visits} visitas",
}

export default messages
