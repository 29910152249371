import { userActions } from "./user-slice"
import ApiClient from "../axios.js"

export const fetchUserData = (email) => {
    return (dispatch) => {
        const storedUser = JSON.parse(localStorage.getItem("user")),
            storedTenant = JSON.parse(localStorage.getItem("tenant")),
            storedRole = JSON.parse(localStorage.getItem("role"))

        if (storedUser && storedTenant && storedRole) {
            dispatch(userActions.setUser(storedUser))
            dispatch(userActions.setTenant(storedTenant))
            dispatch(userActions.setRole(storedRole))
        } else dispatch(userActions.startAllLoaders())

        ApiClient.get("identities/users?id=" + encodeURIComponent(email))
            .then((res) => {
                const userData = res.data.data[0].metadata
                userData.id = res.data.data[0].PK

                let loadingTenant = true,
                    loadingRole = true,
                    tenantId,
                    roleId

                if (userData.tenants?.length > 1) tenantId = localStorage.getItem("selectedTenant")
                else if (userData.tenants?.length > 0) tenantId = userData.tenants[0]
                else tenantId = userData.tenant

                if (userData.roles_tenants?.length > 1) {
                    roleId = userData.roles_tenants.find((r) => r.tenant === tenantId)?.role
                } else roleId = userData.roles ? userData.roles[0] : null

                dispatch(userActions.setUser(userData))
                localStorage.setItem("user", JSON.stringify(userData))


                if (userData.tenants?.length > 1) {
                    ApiClient.get("identities/tenants?id=" + userData.tenants.join())
                        .then((res) => {
                            const tenants = res.data.data.map((t) => {
                                const tenantData = t.metadata
                                tenantData.id = t.PK.replace("TENANT#", "")

                                if (!tenantData.phone || tenantData.phone === "") tenantData.phone = userData.phone
                                if (!tenantData.working_hours) tenantData.working_hours = {}
                                if (tenantData.show_all_conversations_to_executives === undefined)
                                    tenantData.show_all_conversations_to_executives = true

                                if (
                                    !tenantData.source ||
                                    tenantData.source.length === 0 ||
                                    tenantData.source.includes("whatsapp")
                                )
                                    tenantData.has_whatsapp = true

                                if (tenantData.source?.includes("sms")) tenantData.has_sms = true

                                if (
                                    tenantData.attributes &&
                                    Array.isArray(tenantData.attributes) &&
                                    tenantData.attributes.some((a) => a.flag_name === "restaurant" && a.flag === true)
                                )
                                    tenantData.is_restaurant = true

                                if (
                                    tenantData.attributes &&
                                    Array.isArray(tenantData.attributes) &&
                                    tenantData.attributes.some(
                                        (a) => a.flag_name === "linktree_basic" && a.flag === true
                                    )
                                )
                                    tenantData.is_linktree_basic = true

                                if (tenantData.attributes && Array.isArray(tenantData.attributes)) {
                                    tenantData.average_ticket = 18000
                                    let average_ticket = tenantData.attributes.find(
                                        (a) => a.average_ticket && a.flag === true
                                    )
                                    if (average_ticket !== undefined && Object.keys(average_ticket).length > 0) {
                                        tenantData.average_ticket = parseInt(average_ticket.average_ticket)
                                    }
                                }

                                if (tenantId === tenantData.id) {
                                    dispatch(userActions.setTenant(tenantData))
                                    localStorage.setItem("tenant", JSON.stringify(tenantData))

                                }

                                return tenantData
                            })

                            dispatch(userActions.setTenants(tenants))
                            localStorage.setItem("tenants", JSON.stringify(tenants))
                        })
                        .catch((err) => {
                            console.error(err)
                        })
                        .finally(() => {
                            loadingTenant = false
                            if (!loadingRole) dispatch(userActions.endAllLoaders())
                        })
                } else if (tenantId && tenantId !== "") {
                    ApiClient.get("identities/tenants?id=" + tenantId)
                        .then((res) => {
                            const tenantData = res.data.data[0].metadata
                            tenantData.id = tenantId
                            if (!tenantData.phone || tenantData.phone === "") tenantData.phone = userData.phone
                            if (!tenantData.working_hours) tenantData.working_hours = {}
                            if (tenantData.show_all_conversations_to_executives === undefined)
                                tenantData.show_all_conversations_to_executives = true

                            if (
                                !tenantData.source ||
                                tenantData.source.length === 0 ||
                                tenantData.source.includes("whatsapp")
                            )
                                tenantData.has_whatsapp = true

                            if (tenantData.source?.includes("sms")) tenantData.has_sms = true

                            if (
                                tenantData.attributes &&
                                Array.isArray(tenantData.attributes) &&
                                tenantData.attributes.some((a) => a.flag_name === "restaurant" && a.flag === true)
                            )
                                tenantData.is_restaurant = true
                            if (
                                tenantData.attributes &&
                                Array.isArray(tenantData.attributes) &&
                                tenantData.attributes.some((a) => a.flag_name === "linktree_basic" && a.flag === true)
                            )
                                tenantData.is_linktree_basic = true
                            if (tenantData.attributes && Array.isArray(tenantData.attributes)) {
                                tenantData.average_ticket = 18000
                                let average_ticket = tenantData.attributes.find(
                                    (a) => a.average_ticket && a.flag === true
                                )
                                if (average_ticket !== undefined && Object.keys(average_ticket).length > 0) {
                                    tenantData.average_ticket = parseInt(average_ticket.average_ticket)
                                }
                                let site_name = tenantData.attributes.find((a) => a.site_name && a.flag === true)
                                if (site_name !== undefined && Object.keys(site_name).length > 0) {
                                    tenantData.site_name = site_name.site_name
                                }
                                if (tenantData.is_linktree_basic && site_name === undefined) {
                                    console.error(
                                        "Falta configurar el atributo tenant.metadata.attributes.site_name",
                                        tenantData.site_name
                                    )
                                }
                            }

                            dispatch(userActions.setTenant(tenantData))
                            localStorage.setItem("tenant", JSON.stringify(tenantData))

                        })
                        .catch((err) => {
                            console.error(err)
                        })
                        .finally(() => {
                            loadingTenant = false
                            if (!loadingRole) dispatch(userActions.endAllLoaders())
                        })
                } else loadingTenant = false

                if (roleId && roleId !== "") {
                    ApiClient.get("identities/roles?id=" + roleId)
                        .then((res) => {
                            const roleData = res.data.data[0].metadata
                            roleData.id = roleId
                            dispatch(userActions.setRole(roleData))
                            localStorage.setItem("role", JSON.stringify(roleData))

                        })
                        .catch((err) => {
                            console.error(err)
                        })
                        .finally(() => {
                            loadingRole = false
                            if (!loadingTenant) dispatch(userActions.endAllLoaders())
                        })
                } else loadingRole = false

                if (!loadingRole && !loadingTenant) dispatch(userActions.endAllLoaders())
            })
            .catch((err) => {
                console.error(err)
                dispatch(userActions.userError())
            })
    }
}
