import { defaultMessageVariables } from "../constants"
import { isADefaultMessageVariable } from "./replace_default_message_variables"

// Construye los parámetros para enviar un mensaje de plantilla a meta
export function buildMetaTemplateParams(template, user, client, tenant) {
    const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language,
        data = { client, user, tenant }

    const metaParams = {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: client.phone,
        type: "template",
        template: {
            name: template.metadata.name,
            PK: template.PK,
            language: {
                code: locale,
            },
            components: [
                {
                    type: "body",
                    parameters: [],
                },
            ],
        },
    }

    if (template.metadata.components[0]?.type === "HEADER") {
        metaParams.template.components.unshift({
            type: "header",
            parameters: template.metadata.components[0].parameters,
        })

        if (template.metadata.components[0].media) metaParams.media = template.metadata.components[0].media
    }

    const buttonsComponent = template.metadata.components.find((c) => c.type === "BUTTONS")
    if (buttonsComponent) {
        metaParams.template.components.push(buttonsComponent)
        // buttonsComponent.buttons.forEach((btn, i) => {
        //     let btnParams = {}
        //     if (btn.type === "quick_reply") {
        //         btnParams.type = "payload"
        //         btnParams.payload = btn.text
        //     }
        //     if (btn.type === "url") {
        //         btnParams.type = "text"
        //         btnParams.text = ""
        //     }

        //     metaParams.template.components.push({
        //         type: "button",
        //         sub_type: btn.type,
        //         index: i,
        //         parameters: [btnParams],
        //     })
        // })
    }

    let defaultVariables = template.metadata.text.match(/\{{(.+?)\}}/g)
    if (defaultVariables && defaultVariables.length > 0)
        defaultVariables = defaultVariables.filter((v) => isADefaultMessageVariable(v))

    if (defaultVariables && defaultVariables.length > 0) {
        defaultVariables.forEach((v) => {
            let text = ""

            const defaultVariable = defaultMessageVariables.find(
                (dV) => dV.bracket_name === v || dV.alternatives.includes(v)
            )

            if (defaultVariable) {
                if (
                    data[defaultVariable.parent] &&
                    (data[defaultVariable.parent][defaultVariable.prop_name] || defaultVariable.combined_props)
                ) {
                    if (defaultVariable.combined_props)
                        defaultVariable.combined_props.forEach(
                            (prop) => (text += data[defaultVariable.parent][prop] + " ")
                        )
                    else text = data[defaultVariable.parent][defaultVariable.prop_name]
                }
            }

            const index = metaParams.template.components.findIndex((p) => p.type === "body")

            metaParams.template.components[index].parameters.push({
                type: "text",
                text,
            })
        })
    }

    // console.log("Meta Params:", metaParams)
    return metaParams
}

// Agrega los parámetros de variables a un mensaje de plantilla de meta
export function addVariablesToTemplateParams(message, params, customVariables, variableTexts) {
    const newParams = { ...params },
        variables = message.match(/\{{(.+?)\}}/g),
        variablesReplaced = {}

    if (variables && variables.length > 0) {
        const defaultVariables = variables.filter((v) => isADefaultMessageVariable(v))
        const bodyIndex = params.template.components.findIndex((p) => p.type === "body")

        const newComponentParams = variables.map((v) => {
            let text = ""

            if (isADefaultMessageVariable(v)) {
                let index = defaultVariables.findIndex((dv) => dv === v)
                if (index !== -1) text = params.template.components[bodyIndex].parameters[index].text
            } else {
                let index = customVariables.findIndex((cV, i) => cV === v && !variablesReplaced[i])
                if (index !== -1) {
                    text = variableTexts[index]
                    variablesReplaced[index] = true
                }
            }

            return {
                type: "text",
                text,
            }
        })

        newParams.template.components[bodyIndex].parameters = newComponentParams
    }

    // console.log("Updated Meta Params:", newParams)
    return newParams
}

// Construye los parámetros para enviar un mensaje de plantilla a twilio
export function buildTwilioParams(template, user, client, tenant) {
    const data = { client, user, tenant }

    const twilioParams = {
        content_sid: template.metadata.twilio_sid,
        content_variables: {},
        category: template.metadata.category,
        template: {
            name: template.metadata.name,
            PK: template.PK,
        },
    }

    const variables = template.metadata.text.match(/\{{(.+?)\}}/g)

    if (variables && variables.length > 0) {
        variables.forEach((v, i) => {
            twilioParams.content_variables[i + 1] = ""
        })

        const defaultVariables = variables.filter((v) => isADefaultMessageVariable(v))

        if (defaultVariables && defaultVariables.length > 0) {
            defaultVariables.forEach((v) => {
                let text = ""

                const defaultVariable = defaultMessageVariables.find(
                    (dV) => dV.bracket_name === v || dV.alternatives.includes(v)
                )

                if (defaultVariable) {
                    if (
                        data[defaultVariable.parent] &&
                        (data[defaultVariable.parent][defaultVariable.prop_name] || defaultVariable.combined_props)
                    ) {
                        if (defaultVariable.combined_props)
                            defaultVariable.combined_props.forEach(
                                (prop) => (text += data[defaultVariable.parent][prop] + " ")
                            )
                        else text = data[defaultVariable.parent][defaultVariable.prop_name]
                    }
                }

                const index = variables.findIndex((tv, i) => tv === v && twilioParams.content_variables[i + 1] === "")
                if (index !== -1) twilioParams.content_variables[index + 1] = text
            })
        }
    }

    // console.log("Twilio Params:", twilioParams)
    return twilioParams
}

// Agrega los parámetros de variables a un mensaje de plantilla de twilio
export function addVariablesToTwilioTemplateParams(message, params, customVariables, variableTexts) {
    const newParams = { ...params },
        { content_variables } = newParams,
        variables = message.match(/\{{(.+?)\}}/g),
        variablesReplaced = {}

    if (variables && variables.length > 0) {
        variables.forEach((v, i) => {
            if (content_variables[i + 1] === "") {
                const index = customVariables.findIndex((cV, i) => cV === v && !variablesReplaced[i])
                if (index !== -1) {
                    content_variables[i + 1] = variableTexts[index]
                    variablesReplaced[index] = true
                }
            }
        })
    }
    return newParams
}
