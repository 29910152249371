const messages = {
    sequenceTitle: "Cheetabot",
    sequenceDescription:
        "With the Cheetabot message sequences you will be able to automate the initial contact with your clients, qualify them to put a tag and / or refer an executive.",
    sequencePlaceholderName: "Sequence name",
    sequencePlaceholderMessage: "Closing message",
    tooltipSequenceAddMessage: "Add closing message",
    tooltipSequenceAddQuestionWithMessage: "Add question with message",
    tooltipSequenceAddQuestionWithOptions: "Add question with answer options",
    sequenceQuestionFree: "Free question",
    sequenceQuestionwWithMessageFree: "Question with free answer",
    sequenceMessage: "Message",
    sequenceQuestionWithOptions: "Question with options",
    sequenceQuestionwWithOptionsAnswer: "Question with answer options",
    sequenceselectOption: "Action for option {x}",
    sequenceselectButton: "Action {n} for button {x}",
    sequenceOption1: "Create note",
    sequenceOption2: "Archive",
    sequenceOption3: "Add tag",
    sequenceOption4: "Show sub-menu",
    sequenceOption5: "Reply with text and/or url",
    sequenceOption6: "Assign to user",
    sequenceOption7: "Close with message",
    sequenceOption8: "Assign to agents group",
    sequenceOption9: "End sequence",
    sequenceOption10: "Save as client name",
    sequenceOption11: "Save client email",
    sequenceOption12: "Save as company name",
    sequenceOption13: "Cancel Shopify order",
    sequenceOption14: "Request feedback",
    sequencePlaceholderQuestion: "Question",
    sequencePlaceholderOption: "Option",
    sequenceButtonPost: "Post",
    sequenceOptionChild1: "Send Link to schedule",
    sequenceOptionChild2: "Request date and time",
    sequenceMessageOptionChild1: "Cheetabot will send the following link to schedule the appointment",
    sequenceMessageOptionChild2:
        "Cheetabot will request date and time by message, and will create a note with the data. Then it will send a reminder to the client",
    sequenceMessageOptionChild3: " days before the appointment.",
    sequencePlaceHolderLink: "Enter the link here",
    sequencePlaceHolderEndSequence: "Write an ending message or URL",
    sequencePlaceHolderFeedback: "Write a message asking for feedback",
    sequenceName: "Cheetabot name",
    sequenceMessageExpectedResponseType: "Expected response type",
    sequenceMessageNumber: "Numéric",
    sequenceMessageDate: "Date",
    sequenceMessageAlphaNumeric: "Free text",
    sequenceMessagePercentage: "Percentage",
    sequenceMessageEmailAddress: "Email address",
    sequenceMessageLastName: "Last Name",
    sequenceMessageCity: "City",
    sequenceMessageDuration: "Duration",
    sequenceMessageStreetName: "Stret Name",
    sequenceMessageTime: "Time",
    sequenceMessageState: "State",
    sequenceMessageFirstName: "Name",
    sequenceMessagePhoneNumber: "PhoneNumber",
    sequenceMessageCountry: "Country",
    sequenceMessageUpdateBot: "Last update date",
    sequenceMessageAddMoreOption: "Add a new option",
    sequenceMessageUpdatedSuccess: "The bot has been successfully updated",
    sequenceMessageUpdatedError: "There was a problem updating",
    sequenceMessageSaveSuccess: "The bot has been successfully created",
    sequenceMessageSaveError: "An error occurred while creating the bot",
    sequenceMessageDeleteSuccess: "The bot has been successfully deleted",
    sequenceMessageChatInputPlaceholder:
        "Deactivate Cheetabot to write. If you do it, it will stop storing the answers 👀.",
    sequenceMessageChatInputHelp1: "While Cheetabot is active, you cannot send messages.",
    sequenceMessageChatInputHelp2:
        "If you deactivate it, you'll be able to write messages again, but Cheetabot will stop processing your client's answers.",
    sequenceMessageChatInputHelp3:
        "If you activate it again after, it will start it's process with this client all over again.",
    sequenceMessageButtonDelete: "Delete sequence",
    sequenceMessageEnable: "Enable",
    sequenceMessageDisable: "Disabled",
    sequenceMessageAlertWarningAfterUpdate: "This update may take 1 to 3 minutes to take effect, please wait.",
    sequenceMessageAlertWarningAfterCreate: "The creation of this bot may take from 1 to 3 minutes, please wait.",
    sequenceMessageAlertWarningAfterDelete: "This action may take up to 1 minute, please wait.",
    sequenceMessageGoBack: "Return to the previous menu",
    sequenceMessageCantUpdatedBot:
        "It is not possible to update the bot because there is an update in process, please wait at least 3 minutes",
    sequenceMessageButtonConfirmModal: "Confirm",
    sequenceMessageButtonCancelModal: "Cancel",
    sequenceMessageConfirmTitleUpdate: "Confirm Cheetabot update",
    sequenceMessageConfirmTitleDelete: "Confirm delete Cheetabot",
    sequenceMessageConfirmTitleStatus: "Confirm Cheetabot status change",
    sequenceMessageConfirmDescription: "This action will stop all current interactions with this Cheetabot.",
    sequenceMessageConfirmDescription2:
        "That means that any client that is interacting with this Cheetabot will stop receiving answers from it, and any flows in progress will stop.",
    sequenceMessageConfirmDescription3: "Are you sure you want to continue?",

    sendMessageTemplate: "Send start message",
    addAction: "Add action",
    removeAction: "Remove action",
    uncompletedFieldsOnBot: "There are uncompleted fields on the bot, please check and try again",
    botHasSavedRecently: "The bot has been saved recently, please wait 3 minutes before doing any other action.",
    whatToDoWithAnswer: "What do you want to do with the answer?",
    chooseStartMessage: "Choose start message",
    thisTemplateExceedsTheMaximumNumberOfCharacters: "This start message exceeds the maximum number of characters.",
    sendMessageText: "Send text message or URL",
    endMessageType: "End message type",
    sendTemplateWithButtons: "Send start message with buttons",
    endSequenceAndSendMessage: "End sequence and send message/URL",
    addStartMessageWithButtons: "Add start message with buttons",

    cheetabots: "Cheetabots",
    cheetabotDescription:
        "Add messages, questions with free response and with options using the button on the right side from the screen.",
    useThisCheetabotForNewClients: "Use as your main welcome Cheetabot.",
    cheetabotName: "Cheetabot name",
    cheetabotNamePlaceholder: "Enter the name of the Cheetabot",
    cheetabotNameRequired: "The name of the Cheetabot is required",
    cheetabotNameMinLength: "The name of the Cheetabot must be at least 6 characters long",
    cheetabotScriptLength: "Script length",
    lastCheetabotUpdate: "Last update",
    createCheetaBot: "Create Cheetabot",
    edit: "Edit Cheetabot",
    cantEditBotNow: "You can't edit the bot now",
    utterances: "Keywords",
    utterancesDescription: "This cheetabot will be activated when the client writes any of these keywords.",
    utterancesPlaceholder: "Enter a new keyword",
    youAlreadyHaveAWelcomingCheetabot: "You already have a main welcoming Cheetabot: {bot}.",
    youAlreadyHaveABotWithThisName: "You already have a bot with this name.",
    welcomeBot: "Welcome Cheetabot",
    chaseBot: "Chase Cheetabot",
    selectBotType: "Select the type of Cheetabot",
    mainBot: "Main Cheetabot",
    cheetabotType: "Cheetabot type",

    addMessageOrQuestion: "Add message or question",
    cheetabotPreview: "Cheetabot preview",
    cheetabotPreviewHello: "Hello {tenant}, I'm your client 👋",
    cheetabotPreviewName: "John Doe",
    cheetabotPreviewResponse: "Client response",
    cheetabotPreviewLink: "https://your-link.com",
    cheetabotPreviewEndSequenceMessage: "End sequence message",
    utterancesDuped: "The keyword {utterance} is already in use",
    youCannotUseTheBotNameAsAKeyword: "The bot name cannot be a keyword",
    keywordBot: "Keyword cheetabot",
    welcomeBotDesc:
        "This cheetabot will be activated when a client starts a conversation (unless that client uses a keyword for another bot). You can only have one welcome Cheetabot.",
    chaseBotDesc: "This cheetabot has to be activated through a chase.",
    keywordBotDesc: "This cheetabot will be activated when the client writes specific keywords or phrases.",
    chaseAndKeywordBotDesc:
        "This Cheetabot will be activated when the customer types any of the keywords or phrases you have defined and it will also be activated when a customer starts the loyalty bot.",
    chaseAndKeywordBot:"Chases and keyword bot",
    cheetabotPreviewKeyword: "Keyword",
    youNeedAWelcomeCheetabot: "You need to have an active welcome cheetabot.",
    pressThePlusButtonToAddThisKeyword: "Press the + button to add this keyword",

    noNameError: "The name of the Cheetabot is required",
    noUtterancesError: "You must add at least one keyword",
    noQuestionsError: "You must add at least one question",
    onlyClosingMessageError: "You must add at least one question",
    emptyQuestionError: "Question {question} needs text",
    emptyOptionError: "Option {question} needs text",
    noActionSelectedError: "Question {question} needs an action",
    noExpectedAnswerError: "Question {question} needs an expected answer",
    noTagSelectedError: "Question {question} needs a tag",
    noOptionsError: "Question {question} needs at least one option",
    noLinkError: "Question {question} needs a link or message",
    noEndMessageError: "Question {question} needs a message",
    noAgentSelectedError: "Question {question} needs an agent",
    noAgentGroupSelectedError: "Question {question} needs an agent group",
    emptyCloseMessageError: "The closing message needs text",
    noTemplateSelectedError: "Question {question} needs a start message",
    noFeedbackMessageError: "Question {question} needs a feedback message",
    noVariablesAllowedError: "Variables are not allowed in messages on question {question}",

    seeCheetabotAnswers: "See Cheetabot answers",
    cheetabotAnswers: "Cheetabot answers",
    cheetabotAnswersDescription: "Here you can see the answers that clients have given to your Cheetabot.",
    selectCheetabot: "Select Cheetabot",
    chooseAQuestion: "Choose a question",
    todaysAnswers: "Today's answers",
    lastWeeksAnswers: "Last week's answers",
    lastMonthsAnswers: "Last month's answers",
    backToBotsList: "Back to list",
    answersChartTitle: "{rate}% answer rate ({x} answers) to question: {question} of Cheetabot: {bot}",
    selectATimeRange: "Select a timeframe",

    youCheetabotIsBeingCreated: "Your Cheetabot is being created, it will come into effect in a few minutes.",
    nonAlternativeAnswers: "Non alternative answers",
    thisCheetabotIsLinkedToAChase: "This Cheetabot is linked to a chase",
    deletingThisCheetabotWillDeactivateTheChases: "Deleting this Cheetabot will deactivate the chases: {chases}",
    deactivatingThisCheetabotWillDeactivateTheChases:
        "Deactivating this Cheetabot will deactivate the chases: {chases}",
    clientAnswerExamples: "Client answer examples",
    emptyAnswer: "<i>Empty answer</i>",

    noDataFound: "No data found.",
    tryAnotherCheetabot: "Try another timeframe or Cheetabot.",
    tryAnotherCheetabotOrQuestion: "Try another timeframe, Cheetabot or question.",
    tryAnotherTimeframe: "Try another timeframe.",
    anErrorOcurredObtainingCheetabotData:
        "An error ocurred obtaining Cheetabot data. Please try again later or select another Cheetabot.",
    youCanOnlyHaveXCheetabots: "You can only have {x} Cheetabots.",
    youWillNeedToDeleteSomeBotsToCreateANewOne: "You will need to delete some Cheetabots to create a new one.",
}

export default messages
