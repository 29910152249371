const messages = {
    restaurantMenu_title: "Link de carta",
	restaurantMenu_desc: "Ingresa aqui el sitio al que tus clientes llegarán tras escanear el código QR en la mesa.",
	restaurantMenu_success: "La URL se ha cambiado con éxito.",
	restaurantMenu_invalid: "La URL ingresada parece no estar funcionando, verifica que esté ingresada correctamente.",
	restaurantMenu_updateError: "Ha ocurrido un error interno. El link de carta no se ha actualizado.",
	restaurantMenu_fetchError: "Ha ocurrido un error al obtener la URL actual.",
	restaurantMenu_saveLabel: "Guardar Cambios"
}

export default messages
