const messages = {
    satisfaction: "Satisfaction",
    customerComments: "Customer",
    dateComment: "Date",
    comments: "Comments",
    category: "Category",
    goodExperience: "Good experience",
    badExperience: "Bad experience",
    doesntKnowDoesntAnswer: "Out of alternatives",
    satisfactionDesc:
        "Here you can see your customers' answers to the {x} satisfaction surveys sent from {from} to {to}.",
    youDontHaveAnySatisfactionSurveys: "You don't have any satisfaction surveys",
    noDataFoundForThisDateRange: "No data found for this date range",
    clientAnswers: "Client answers",
    answersSummary: "Answers summary",
    badExperiencesBreakdown: "Bad experiences breakdown",
    answersByDay: "Answers by day",
    obtainingSatisfactionData: "Obtaining satisfaction data...",
    feedback: "Feedback",
    customerFeedback: "Customer feedback",
    anErrorOcurredObtainingSatisfactionData: "An error ocurred obtaining satisfaction data, please try again.",
    anErrorOcurredObtainingFeedbackData: "An error ocurred obtaining feedback data, please try again.",
    noFeedbackFound: "No feedback found for this date range.",
    dataUpdatedevery3Hours: "Data is updated every 3 hours.",
    commentsBeforeThe25OfSeptember2023AreNotAvailable: "Comments before the 26 of September 2023 are not available.",
    xClientsDidNotSendFeedbackOrSentIncompleteFeedback:
        "{x} clients did not send feedback or sent feedback less than 2 characters long.",
    infoSatisfaction:"Response from your customers to the Bot with question: How was our service?", 
    infohowDidYouMeetUs:"Your customers' response to the bot with the question: How did you hear about us?",
    howDidYouMeetUs:"How did you hear about us?"
}

export default messages
