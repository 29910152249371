import dayjs from "dayjs"

const fillAndCheckDailyArr = (arr, dateFrom, duration) => {
    let updateArr = [...arr]

    const date = new Date(dateFrom.format("YYYY/MM/DD"))

    for (let i = 0; i < duration; i++) {
        if (!updateArr[i] || updateArr[i] === undefined) {
            const name = new Date(date).toLocaleDateString("es-ES", {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric",
            })

            updateArr[i] = {
                name,
                y: 0,
            }
        }

        date.setUTCDate(date.getUTCDate() + 1)
    }
    return updateArr
}

// Procesa los datos de bot sessions

export const processDailyClients = (dailyClients, dateFrom, duration, period) => {
    let updateDailyClients = []

    dailyClients.forEach((cl) => {
        const clDate = dayjs(cl.date, "DD/MM/YYYY").hour(12),
            dateName = new Date(clDate.format("YYYY/MM/DD")).toLocaleDateString("es-ES", {
                weekday: "long",
                month: "long",
                day: "numeric",
                year: "numeric",
            }),
            dayIndex = period * (duration + 1) + Math.ceil(clDate.diff(dateFrom, "day", true))

        if (dayIndex <= duration && dayIndex >= 0) {
            updateDailyClients[dayIndex] = {
                y: cl.total,
                name: dateName,
            }
        }
    })
    updateDailyClients = fillAndCheckDailyArr(updateDailyClients, dateFrom, duration)

    // console.log("Daily Clients data:", dailyClients)
    console.log("Daily Clients view data:", updateDailyClients)

    return updateDailyClients
}
