const messages = {
    sequenceTitle: "Cheetabot",
    sequenceDescription:
        "Com as sequências de mensagens do Cheetabot, você pode automatizar o contato inicial com seus clientes, classificá-los para adicionar uma etiqueta e/ou encaminhá-los para um executivo.",
    sequencePlaceholderName: "Nome da sequência",
    sequencePlaceholderMessage: "Mensagem de encerramento",
    tooltipSequenceAddMessage: "Adicionar mensagem de encerramento",
    tooltipSequenceAddQuestionWithMessage: "Adicionar pergunta com mensagem",
    tooltipSequenceAddQuestionWithOptions: "Adicionar pergunta com opções de resposta",
    sequenceQuestionFree: "Pergunta livre",
    sequenceQuestionwWithMessageFree: "Pergunta com resposta livre",
    sequenceMessage: "Mensagem",
    sequenceQuestionWithOptions: "Pergunta com opções",
    sequenceQuestionwWithOptionsAnswer: "Pergunta com opções de resposta",
    sequenceselectOption: "Ação para a opção {x}",
    sequenceselectButton: "Ação {n} para o botão {x}",
    sequenceOption1: "Criar nota",
    sequenceOption2: "Arquivar",
    sequenceOption3: "Adicionar etiqueta",
    sequenceOption4: "Mostrar sub-menú",
    sequenceOption5: "Responder com texto e/ou link",
    sequenceOption6: "Atribuir a usuário",
    sequenceOption7: "Fechar com mensagem",
    sequenceOption8: "Atribuir a um grupo de executivos",
    sequenceOption9: "Finalizar sequência",
    sequenceOption10: "Guardar como nome do cliente",
    sequenceOption11: "Guardar como  email",
    sequenceOption12: "Guardar como nome da empresa",
    sequenceOption13: "Cancelar pedido Shopify",
    sequenceOption14: "Solicitar feedback",
    sequencePlaceholderQuestion: "Pergunta",
    sequencePlaceholderOption: "Opção",
    sequenceButtonPost: "Publicar",
    sequenceOptionChild1: "Enviar Link para agendar",
    sequenceOptionChild2: "Solicitar data e hora",
    sequenceMessageOptionChild1: "O Cheetabot enviará o seguinte link para agendar a consulta",
    sequenceMessageOptionChild2:
        "O Cheetabot solicitará a data e a hora por mensagem, criará uma nota com os dados e depois enviará um lembrete ao cliente ",
    sequenceMessageOptionChild3: " dias antes da consulta.",
    sequencePlaceHolderLink: "Insira o link aqui",
    sequencePlaceHolderEndSequence: "Escreva uma mensagem final ou URL",
    sequencePlaceHolderFeedback: "Escreva uma mensagem pedindo feedback",
    sequenceName: "Nome do Cheetabot",
    sequenceMessageExpectedResponseType: "Tipo de resposta esperada",
    sequenceMessageNumber: "Numérico",
    sequenceMessageDate: "Data",
    sequenceMessageAlphaNumeric: "Texto livre",
    sequenceMessagePercentage: "Porcentagem",
    sequenceMessageEmailAddress: "Email",
    sequenceMessageLastName: "Sobrenome",
    sequenceMessageCity: "Cidade",
    sequenceMessageDuration: "Duração",
    sequenceMessageStreetName: "Endereço",
    sequenceMessageTime: "Hora",
    sequenceMessageState: "Estado",
    sequenceMessageFirstName: "Nome",
    sequenceMessagePhoneNumber: "Número de telefone",
    sequenceMessageCountry: "País",
    sequenceMessageUpdateBot: "Última data de atualização",
    sequenceMessageAddMoreOption: "Adicionar nova opção",
    sequenceMessageUpdatedSuccess: "O bot foi atualizado com sucesso",
    sequenceMessageUpdatedError: "Ocorreu um problema ao atualizar",
    sequenceMessageSaveSuccess: "O bot foi criado com sucesso",
    sequenceMessageSaveError: "Ocorreu um erro ao criar o bot",
    sequenceMessageDeleteSuccess: "O bot foi removido com sucesso",
    sequenceMessageChatInputPlaceholder:
        "Desative o Cheetabot para escrever. Ao fazer isso, ele deixará de coletar as respostas 👀.",
    sequenceMessageChatInputHelp1: "Enquanto o Cheetabot estiver ativo, você não pode enviar mensagens.",
    sequenceMessageChatInputHelp2:
        "Se desativar, poderá escrever novamente, mas o Cheetabot deixará de processar as respostas deste cliente.",
    sequenceMessageChatInputHelp3: "Se reativar depois, o Cheetabot começará o processo com este cliente do zero.",
    sequenceMessageButtonDelete: "Excluir sequência",
    sequenceMessageEnable: "Ativar",
    sequenceMessageDisable: "Desativar",
    sequenceMessageAlertWarningAfterUpdate:
        "Esta atualização pode levar de 1 a 3 minutos para ter efeito, por favor, aguarde.",
    sequenceMessageAlertWarningAfterCreate: "A criação deste bot pode levar de 1 a 3 minutos, por favor, aguarde.",
    sequenceMessageAlertWarningAfterDelete: "Esta ação pode levar até 1 minuto, por favor, aguarde.",
    sequenceMessageGoBack: "Voltar ao menu inicial",
    sequenceMessageCantUpdatedBot:
        "No é possível atualizar o bot porque há uma atualização em andamento, por favor, espere pelo menos 3 minutos.",
    sequenceMessageButtonConfirmModal: "Confirmar",
    sequenceMessageButtonCancelModal: "Cancelar",
    sequenceMessageConfirmTitleUpdate: "Confirmar atualização do Cheetabot",
    sequenceMessageConfirmTitleDelete: "Confirmar exclusão do Cheetabot",
    sequenceMessageConfirmTitleStatus: "Confirmar alteração de estado do Cheetabot",
    sequenceMessageConfirmDescription: "Esta ação irá interromper todas as interações atuais com este Cheetabot.",
    sequenceMessageConfirmDescription2:
        "Isto significa que qualquer cliente que esteja conversando com este Cheetabot não receberá mais respostas, e qualquer fluxo em andamento será interrompido.",
    sequenceMessageConfirmDescription3: "¿Você tem certeza de que deseja continuar?",

    sendMessageTemplate: "Enviar mensagem inicial",
    addAction: "Adicionar ação",
    removeAction: "Eliminar ação",
    uncompletedFieldsOnBot: "Você tem campos não preenchidos, por favor, revise e tente novamente",
    botHasSavedRecently: "O bot foi salvo recentemente. Por favor, espere 3 minutos antes de fazer modificações.",
    whatToDoWithAnswer: "¿O que fazer com a resposta?",
    chooseStartMessage: "Selecione a mensagem de início",
    thisTemplateExceedsTheMaximumNumberOfCharacters:
        "Esta mensagem de início ultrapassa o limite máximo de caracteres permitidos.",
    sendMessageText: "Enviar mensagem de texto ou URL",
    endMessageType: "Tipo de mensagem final",
    sendTemplateWithButtons: "Enviar mensagem de início com botões",
    endSequenceAndSendMessage: "Finalizar sequência e enviar mensagem/URL",
    addStartMessageWithButtons: "Adicionar mensagem de início com botões",

    cheetabots: "Cheetabots",
    cheetabotDescription:
        "Adicione mensagens, perguntas com resposta livre e com opções usando a barra de botões localizada à direita da tela.",
    useThisCheetabotForNewClients: "Usar como Cheetabot de boas-vindas principal.",
    cheetabotName: "Nome do Cheetabot",
    cheetabotNamePlaceholder: "Escreva o nome do Cheetabot",
    cheetabotNameRequired: "O nome do Cheetabot é obrigatório",
    cheetabotNameMinLength: "O nome do Cheetabot deve ter pelo menos 6 caracteres",
    cheetabotScriptLength: "Comprimento do script",
    lastCheetabotUpdate: "Última atualização",
    createCheetaBot: "Criar Cheetabot",
    editCheetaBot: "Editar Cheetabot",
    cantEditBotNow: "No é possível editar o bot neste momento",
    utterances: "Palavras-chave",
    utterancesPlaceholder: "Escreva uma palavra-chave",
    utterancesDescription: "Este Cheetabot será ativado quando o cliente digitar alguma destas palavras.",
    youAlreadyHaveAWelcomingCheetabot: "Você já possui um Cheetabot de boas-vindas principal: {bot}.",
    youAlreadyHaveABotWithThisName: "Você já possui um Cheetabot com este nome.",
    welcomeBot: "Cheetabot de boas-vindas",
    chaseBot: "Cheetabot de perseguição",
    selectBotType: "Selecione o tipo de Cheetabot",
    mainBot: "Cheetabot Principal",
    cheetabotType: "Tipo de Cheetabot",

    addMessageOrQuestion: "Adicionar mensagem ou pergunta",
    cheetabotPreview: "Visualização prévia",
    cheetabotPreviewHello: "Olá {tenant}, sou seu cliente 👋",
    cheetabotPreviewName: "Juan Perez",
    cheetabotPreviewResponse: "Resposta do cliente",
    cheetabotPreviewLink: "https://tu-link.com",
    cheetabotPreviewEndSequenceMessage: "Mensagem de finalizar sequência",
    utterancesDuped: "A palavra-chave {utterance} já está sendo usada por outro Cheetabot",
    youCannotUseTheBotNameAsAKeyword: "O nome do bot não pode ser uma palavra-chave",
    keywordBot: "Cheetabot de palavras-chave",
    welcomeBotDesc:
        "Este Cheetabot será ativado quando um cliente iniciar uma conversa (exceto se usar uma palavra-chave ou frase-chave para outro bot). Você só pode ter um Cheetabot de boas-vindas.",
    chaseBotDesc: "Este Cheetabot deve ser ativado através de uma perseguição.",
    chaseAndKeywordBotDesc:
        "Esse Cheetabot será ativado quando o cliente digitar qualquer uma das palavras-chave ou frases que você definiu e também será ativado quando um cliente iniciar o bot de fidelidade.",
    chaseAndKeywordBot:"Chases y palabras clave",
    keywordBotDesc:
        "Este Cheetabot será ativado quando o cliente digitar alguma das palavras ou frases-chave que você definiu.",
    cheetabotPreviewKeyword: "Palavra-chave",
    youNeedAWelcomeCheetabot: "Você precisa ter um Cheetabot de boas-vindas ativo.",
    pressThePlusButtonToAddThisKeyword: "Pressione o botão + para adicionar esta palavra-chave.",

    noNameError: "O nome do Cheetabot é obrigatório",
    noUtterancesError: "Você precisa adicionar pelo menos uma palavra-chave",
    noQuestionsError: "Você precisa adicionar pelo menos uma pergunta",
    onlyClosingMessageError: "Você precisa adicionar pelo menos uma pergunta",
    emptyQuestionError: "Você precisa adicionar um texto para a pergunta {question}",
    emptyOptionError: "Você precisa adicionar um texto para a opção {question}",
    noActionSelectedError: "Você precisa selecionar uma ação para a pergunta {question}",
    noExpectedAnswerError: "Você precisa selecionar um tipo de resposta esperada para a pergunta {question}",
    noTagSelectedError: "Você precisa selecionar uma etiqueta para a pergunta {question}",
    noOptionsError: "Você precisa adicionar pelo menos uma opção para a pergunta {question}",
    noLinkError: "Você precisa adicionar um link ou texto para a pergunta {question}",
    noEndMessageError: "Você precisa adicionar uma mensagem final para a pergunta {question}",
    noAgentSelectedError: "Você precisa selecionar um executivo para a pergunta {question}",
    noAgentGroupSelectedError: "Você precisa selecionar um grupo de executivos para a pergunta {question}",
    emptyCloseMessageError: "Você precisa adicionar uma mensagem de encerramento",
    noTemplateSelectedError: "Você precisa selecionar uma mensagem de início para a pergunta {question}",
    noFeedbackMessageError: "Você precisa adicionar uma mensagem de feedback para a pergunta {question}",
    noVariablesAllowedError: "Variáveis não são permitidas em mensagens para a pergunta {question}",

    seeCheetabotAnswers: "Ver respostas ao Cheetabot",
    cheetabotAnswers: "Respostas ao Cheetabot",
    cheetabotAnswersDescription: "Aqui você pode ver as respostas de seus clientes às perguntas do seu Cheetabot.",
    selectCheetabot: "Selecione um Cheetabot",
    chooseAQuestion: "Selecione uma pergunta",
    todaysAnswers: "Respostas de hoje",
    lastWeeksAnswers: "Respostas da última semana",
    lastMonthsAnswers: "Respostas do último mês",
    backToBotsList: "Voltar para a lista",
    answersChartTitle: "{rate}% taxa de resposta ({x} respostas) à pergunta: {question} do Cheetabot: {bot}",
    selectATimeRange: "Selecione um intervalo de tempo",

    youCheetabotIsBeingCreated: "Seu Cheetabot está sendo criado, levará alguns minutos para estar em funcionamento.",
    nonAlternativeAnswers: "Respostas fora de alternativa",
    thisCheetabotIsLinkedToAChase: "Este Cheetabot está vinculado a uma perseguição",
    deletingThisCheetabotWillDeactivateXChases:
        "Ao excluir este Cheetabot, as perseguições serão desativadas: {chases}",
    deactivatingThisCheetabotWillDeactivateTheChases:
        "Ao desativar este Cheetabot, as perseguições serão desativadas: {chases}",
    clientAnswerExamples: "Exemplos de respostas",
    emptyAnswer: "<i>Resposta vazia</i>",

    noDataFound: "Nenhum dado encontrado.",
    tryAnotherCheetabot: "Tente com outro intervalo de tempo ou outro Cheetabot.",
    tryAnotherCheetabotOrQuestion: "Tente com outro intervalo de tempo, Cheetabot ou pergunta.",
    tryAnotherTimeframe: "Tente com outro intervalo de tempo.",
    anErrorOcurredObtainingCheetabotData:
        "Ocorreu um erro ao obter os dados do Cheetabot. Por favor, tente novamente mais tarde ou selecione outro Cheetabot.",
    youCanOnlyHaveXCheetabots: "Você só pode ter {x} Cheetabots.",
    youWillNeedToDeleteSomeBotsToCreateANewOne: "Você precisará excluir alguns Cheetabots para criar um novo.",
}

export default messages
