const messages = {
    monthlySales: "Monthly sales",
    eCommerceSales: "E-Commerce sales",
    monthlyGoal: "Goal for this month",
    cheetrackSales: "Total sales assisted by Cheetrack",
    nonCheetrackSales: "Sales not assisted by Cheetrack",
    dailyGoal: "Daily goal",
    increaseSales: "Increase sales",
    dailyAverage: "Daily average",
    toShowYourSalesHereFirstIntegrate: "To show your sales here, first you must integrate Cheetrack with Shopify!",
    dailySales: "Daily sales",
    lastMonthDailyAvg: "Previous months daily average",
    youAreXFromYourGoal: "You are {x} from your monthly goal 💪",
    toAchieveYourGoalYouNeedToSellXDaily: "To achieve this goal you must sell {x} daily",
    youExceededYourGoal: "You are {x} above your monthly goal! 🥳",
    youHaveSelledAnAvgOf: "You have soled an average of {x} a day!",
    youSoldXThisMonth: "You sold {x} during {month}",
    cheetrackHelpedYouXThatMonth: "Cheetrack helped you with {x} of those sales",
    loadingData: "Loading data...",
    noDataToDisplay: "No data to display",
    youHaveNotYetConnectedToECommerce: "You are not connected to Shopify!",
    clickToConnectToSeeYourDataHere: "Click the Shopify icon to connect and see your data here!",
    goal: "Goal",
    anErrorOcurredGettingYourData: "An error ocurred getting your data ☹️!",
    anErrorOcurredWithRangeDatetime: "Querying data older than 60 days may cause slower response times and impact performance. Please consider selecting a more recent date range ⚡️",
    pleaseTryAgainLater: "Please try again later",
    thereIsNoData: "There is no data to show!",
    youEitherNeedToConnectOrHaveNoData:
        "You either haven't connected with Shopify or have no data available for this period",
    obtainingYourData: "Obtaining your Shopify data",
    cartsRecoveredByCheetrack: "Checkouts recovered by Cheetrack this month",
    obtainingLostData: "Obtaining abandoned checkouts data",
    anErrorOcurredGettingYourLostCartData: "We couldn't get your abandoned checkouts data 😖",
    cheetrackHasRecoveredXCarts: "Cheetrack has recovered {x} abandoned checkouts 🛒 this month!",
    thisAmountsToXCartsADay: "This averages {x} by recovered cart!",
    dailyLostCarts: "Daily abandoned checkouts",
    cheetrackRecoverdCarts: "Daily Cheetrack recovered checkouts",
    cheetrackHasntRecoveredCartsYet: "Cheetrack has not recovered any abandoned checkouts yet 😵‍💫!",
    youMayNeedToWaitALittle: "You may need to wait a little for checkouts to be recovered or for us to gather the data",
    thereisNoLostCartsData: "We found no abandoned checkouts' data for you 😨!",
    checkYourShopifyConnectionOrPermssions: "Check that you provided us with the proper permissions on Shopify!",
    obtainingBuyersData: "Obtaining your top purchasing clients data",
    anErrorOcurredWithYourBuyersData: "Oops! An error ocurred trying to obtain your customers' data",
    thereIsNoBuyersData: "We found no data on your customers' purchases",
    thereMayBeAnIssueWithYourShopifyPermissions: "There may be an issue with your Shopify's permissions",
    saleNumbersDescription: "Sales numbers are raw. They include taxes, shipping costs, and discounts.",
    toMyTop: " to my top ",
    clientName: "Client name",
    purchasesForLast30Days: "Purchases for last 30 days",
    cheetrackHasAssistedYouWithXSalesTheLastYDays:
        "Cheetrack has assisted you with {x} on sales these last {x} days 🤩!",
    top10SalesTemplates: "Top 10 start messages with most sales",
    totalSales: "Total sales on Shopify",
    clientsReached: "Clients reached",
    averageSale: "Average customer ticket",
    cheetrackAssistedSales: "Cheetrack assisted sales",
    cheetrackAssistedSalesMD: "Cheetrack assist. sales",
    ordersCreated: "Orders created",
    openRate: "Open rate",
    stopMessages: "Stop messages",
    soon: "Soon",
    last7Days: "Last 7 days",
    last15Days: "Last 15 days",
    last30Days: "Last 30 days",
    thisAmountsToXOfYourTotalSales: "This amounts to {x}% of your total Shopify sales for the same period.",
    comparedToTheXDaysBefore: "Compared to the {x} days before.",
    selectDataToShow: "Show compiled sales:",
    showDataFrom: "Show sales for start messages:",
    cheetrackAssistedSaleDesc:
        "A Cheetrack assisted sale is any sale up to 5 days after a Cheetrack start message was sent to that client.",
    dailySalesForXTemplates: "Daily sales for {x} start message",
    dailySalesForXYTemplates: "Daily sales for {x} start messages",
    dailySalesForXYZTemplates: "Daily sales for {x} and {y} other start messages",
    dailySalesForTop10Templates: "Daily sales for your 10 best selling start messages",
    dailySalesAssistedByCheetrack: "Daily sales assisted by Cheetrack",
    averageSaleDesc: "Cheetrack assisted sales / orders",
    salesDataUpdatesDaily: "Sales data updates every 24 hrs.",
    salesWarningModalTitle: "Warning Report",
    saleswarningModalBody:
        "You are requesting the detail of the sales assisted by Cheetrack. It will take a few minutes to reach the e-mail of the administrator users registered on our platform.",
    salesGetReport: "Send email report",
    salesStartDate: "Start date",
    salesEndDate: "End date",
    salesButtonConfirmation: "Understood",
    salesDatePickerAnswerTitle: "Do you want to download a backup of assisted sales?",
    metricsTemplateInfo: "Important: When multiple templates are sent to a client (e.g., cyber, promo, abandoned cart), metrics are only counted for the last template sent in the sequence.",
    metricsTemplateShort: "Metrics are associated with the last template sent",
}

export default messages
