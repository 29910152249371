const messages = {
    didYouKnow: "¿Sabías que...?",
    cheetahFact1: "Un chita puede correr a 120 Km/h",
    cheetahFact2: "Los chitas cazan durante el día",
    cheetahFact3: "Sólo quedan entre 9000-12000 chitas en África",
    cheetahFact4: "Los chitas nacen en camadas de 3 a 8 cachorros",
    cheetahFact5: "Un chita puede cuidarse a si mismo a los 16-24 meses de edad",
    cheetahFact6: "Los chitas cazan conejos, jabalíes, gacelas y aves",
    cheetahFact7: "El chita es el animal terrestre más rápido",
    cheetahFact8: "Los chitas no retraen sus garras al correr",
    cheetahFact9: "Cuando corre, un chita usa su cola como timón",
    cheetahFact10: "Las líneas negras en torno a los ojos del chita le ayudan contra el sol",
    cheetahFact11: "Un chita puede correr 100 metros en 6 segundos",
    cheetahFact12: "Un chita puede alcanzar los 100 Km/h en sólo 3 segundos!",
    cheetahFact13: "Después de una carrera, un chita necesita 30 minutos para recuperar el aliento",
    cheetahFact14: "Los chitas no rugen, pero si maúllan y ronronean",
    cheetahFact15: "Los chitas sólo beben agua 2 veces por semana",
    cheetahFact16: "Un chita corre 50% más rápido que Usain Bolt",
    cheetahFact17: "A máxima velocidad, un chita avanza 7 metros por paso",
    cheetahFact18: "Los chitas pueden ver a 5 kilómetros de distancia",
    cheetahFact19: "Un chita tiene entre 2000 y 3000 manchas",
    cheetahFact20: "Los chitas eran criados como mascotas y usados para cazar en el Antiguo Egipto",
    cheetahFact21: "La palabra chita viene del Hindi y significa pintado o adornado",
    cheetahFact22: "Puedes escribir en negrita usando * de esta forma: ",
    cheetahFact23: "Puedes escribir en cursiva usando _ de esta forma: ",
    cheetahFact24: "Puedes conectar Cheetrack a tus cuentas de Facebook e Instagram",
    cheetahFact25: "Puedes usar tus propias variables en tus mensajes",
    cheetahFact26: "Cheetrack puede asignar mensajes entrantes a tus ejecutivos automáticamente",
    cheetahFact27: "Puedes limitar a tus ejecutivos a ver sólo las conversaciones asignadas a ellos",
    cheetahFact28:
        "WhatsApp impone un limite diario a tus mensajes, entre más mensajes envíes, más alto será el limite",
}

export default messages
