const messages = {
    unassigned: "Not assigned",
    myAssignments: "My assignments",
    all: "All",
    allFemale: "All",
    saveNumber: "Save number",
    conversationAssignedTo: "Conversation assigned to",
    archiveConversation: "Archive",
    openConversation: "Open conversation",
    chatInputPlaceholder: "Type something here",
    messageStartedAt: "This conversation was initiated {date} at {time}",
    reply: "Reply",
    resendMessage: "Resend the message",
    remindMessage: "Remind me of this later",
    highlightMessage: "Highlight message",
    deleteMessage: "Delete message",
    reminderText: "You asked me to remind you of",
    assignPopoverTitle: "Select a team member to assign this conversation",
    selectTagPopoverTitle: "Select a tag to classify this sale.",
    takeNotePlaceholder: "Type a note here",
    onlyYouCanSeeNote: "Only you can see this",
    chatTemplatePlaceHolder: "Select a start message to send",
    chatCalenderPopoverTitle: "Schedule this conversation for later",
    chatCalenderPopoverInputPlaceholder: "Ingresa una nota aquí",
    chatFilterAssignedTo: "Assigned to",
    chatFilterAssignedToAbbrv: "Assign. to",
    archiveChatMobileTitle: "Do you want archive this conversation?",
    chatWriteNotesTitle: "Write notes for this conversation",
    chatWriteNote: "Write a new note here",
    addFile: "Add file",
    fileCommentPlaceholder: "Type a commentary to go with your file",
    fileCommentPlaceholderDisabled: "You cannot send files to this conversation, send a start message first",
    fileCommentPlaceholderMulticlientDisabled: "You cannot send files to multiple conversations simultaneously",
    noTemplates: "There are no start messages to send, <strong>create a start message</strong>",
    imgNotAvailable: "Image not available",
    fileNotAvailable: "File not available",
    msgNotAvailable: "Message not available",
    addToMyClients: "Add to my clients",
    addNewCustomer: "Add new client",
    fileOverSizeError: "The added file is larger than 5 MB.",
    fileWrongFormatError:
        "The added file format is not allowed. Recommended file formats are JPG, PNG, AMR, MP4, OGG or PDF.",
    instagramFileWrongFormatError: "Instagram and Facebook only allow JPG, PNG, BMP or ICO formats.",
    removeFileError: "Please remove file.",
    fileNotReady: "File's not ready, please try again in a moment",

    workingHours: "Working hours",
    setWorkingHours: "Set working hours",
    workingHoursModalDescription:
        "Set work hours to let your clients know what times you are working and when you are available to respond. If a client sends you a message outside of these hours, Cheetabot will reply with your custom message.",
    useWorkingHours: "Use working hours",
    workingHoursSelectTimeZone: "Select your company's time zone:",
    workingHoursSelectTimes: "Select your company's working hours:",
    workingHoursAutoReplyMessage: "Set your auto reply:",
    sameHoursAllWeek: "The company's working hours are the same Monday to Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    dayOff: "Day off",
    copyTimeToAll: "Copy time to all",

    messageError: "Error sending message",
    error63016:
        "This message was rejected by WhatsApp because you are outside the 24-hour window.\nPlease check you client's last message was sent within 24 hours or you are using an approved Start Message.",
    addContact: "Add Contact",
    contactAlreadyAdded: "Contact already added",
    anErrorOcurredObtainingContactInfo: "An error ocurred obtaining the shared contact information",
    selectedConversation: "Selected Conversation",
    max80: "(Max 40)",
    theActionsYouTakeWillBeAppliedToAllTheSelectedConversations:
        "The actions you take will be applied to all the selected conversations",
    messagePopoverTagsTitle: "Select a tag to classify this sale.",
    messagePopoverTagsCreateTags: "You do not have any tag created click on the button to create your first tag",
    addStage: "Add new tag",
    assignedToPlural: "Assigned to ",
    assignedToOthers: "Assigned to others",
    changesMayTakeAMomentToShow: "Changes may take up to 20 seconds to show on reload",
    shareYourWhatsApp: "Share your WhatsApp",
    shareYourWhatsappTitle: "Share your WhatsApp number",
    shareYourWhatsappDescription:
        "Copy and make this link available on your social networks and website for your clients to contact you through WhatsApp.",
    buttonShareWhatsapp: "I get it",
    titleCreateTag: "Create tag",
    titleUpdateTag: "Update tag",
    placeholderNameTag: "Name tag",
    selectColor: "Select a color",
    buttonSave: "Save",
    dontForgetToShareYourWhatsAppNumber: "Don't forget to share your WhatsApp number!",
    createFastAnswer: "Create a quick reply",
    newFastAnswer: "New quick reply",
    notQuickText: "There are no quick responses to submit",
    chatQuickTextPlaceHolder: "Choose a quick reply to send in this conversation",
    selectAnAnswer: "Select an answer",
    seeConversationsByAssigned: "Filter conversations by assigned user",
    seeConversationsByAnswer: "Only show pending conversations",
    seeConversationsByStatus: "Filter conversations by status",
    otherFilters: "Other conversation filters",
    file: "File",
    camera: "Camera",
    scheduleTo: "Schedule to ",
    halfAnHour: "30 minutes",
    pickADate: "Pick a date",
    schedule: "Schedule",
    youHaveTimeToResponse: "You have {time} to respond. Type / to open quick replies.",
    conversationInChaseWithQuickReply: "Sending a quick reply will Not stop this chase.",
    conversationInChaseWithTemplate: "Sending a start message will Not stop this chase.",
    timeWindowExplanation1: "WhatsApp limits you, as a business, to only send previously pre-approved start messages.",
    timeWindowExplanation2:
        "If someone responds or sends a direct message to you, a 24-hour window opens up that allows you to freely send messages to that number.",
    timeWindowExplanation3: "The time window is currently displayed on the message input",
    timeWindowExplanation4:
        "After that time window closes, you will again be limited to sending pre-approved start messages",
    chatBlanktip1: "Send a start message to begin this conversation.",
    chatBlanktip2: "If you a receive a message, a 24-hour window will open up for you to freely respond.",
    thisConversationHasMovedTo: "Conversation moved to ",
    conversationsBlanktip:
        "You have no conversations, you can start one by creating a new client or receiving a message to the number {phone_number}",
    theAdminHasSetTheFollowingWorkHours: "The admin has set the following working hours:",
    mondayToFriday: "Monday to Friday",
    outsideWorkingHours: "You're connected outside working hours",
    inWorkingHours: "{hours} working hours remaining",
    workingHoursNotSet: "Working hours not set",
    assignThisConversation: "Assign conversation",
    scheduleConversation: "Schedule conversation",
    conversationsSearchBlanktip:
        "You have no conversations that fit your search or filter criteria. You can start new conversations by creating a new client or receiving a message to the number {phone_number}",
    newMessageNotification: "You have received a message from ",
    newClientNotification: "You have received a message from a new client",
    conversationsWithBotCantBeMultiselected: "Conversations with an active bot can't be selected",
    disabledChatMsg:
        "Your account has been restricted due to payment issues or because you haven't yet set a subscription plan.",
    disabledChatMsgPlan: "You need to set a subscription plan before being able to send any messages!",
    disabledChatMsgPlanBtn: "Click here to go set a subscription",
    disabledChatMsgPhoneNumber: "You need to connect a chat platform to this account!",
    disabledChatMsgPhoneNumberBtn: "Click here to connect with a chat platform",
    activateNotifications: "Activate notifications",
    disableNotifications: "Disable notifications",
    soundFileError: "An error occurred with the audio file.",
    anErrorOcurredSelectingFile:
        "A problem ocurred, you either didn't select a file or the selected file has an issue, please try another file.",
    sendingFiles: "Sending files...",
    anErrorOcurredSendingAFile: "An error ocurred sending one of your files, please try again or change the file.",
    thisConversationsHasBeenAssignedTo: "This conversation has been assigned to ",
    thisConversationHasCheetabotActive: "This conversation is being answered by Cheetabot",
    thisConversationHasBlockActive: "This conversation will be skipped on segment chases.",
    thisConversationHasAChaseActuve: "This conversation has an active chase.",
    thisConversationHasAShopifyChaseActuve: "This conversation has an active Shopify chase.",
    automaticAgentAssignmentIsActive: "Automatic executive assignment is active.",
    lastAssignedAgentWas: "Last assigned executive was ",
    chatStatusUnreadDesc: "Client messages have not been read by the assigned executive.",
    chatStatusPendingDesc: "Last message was sent by client.",
    chatStatusAnsweredDesc: "Conversation answered.",
    whatsAppShareDesc:
        "You can also share your WhatsApp on Facebook, to do that you must follow the instructions on the following URL https://www.facebook.com/[your company's name on Facebook]/settings/?tab=whatsapp_management. Replacing [youu company`s name on Facebook] with your company's name as written on Facebook",
    onlyTheAdminCanSetWorkingHours: "Only the admin can set this company's working hours.",
    dialogConfirmDeleteTagDescription1:
        "This Tag is associated with {{entities}}, you must first remove this functionality or unlink the tag from it before you can remove this tag",
    dialogConfirmDeleteTagDescription2:
        "This tag is assigned to {{number_clients}} clients, when eliminated it will be unassigned from these conversations, and this change will be irreversible. Are you sure you want to continue?",
    dialogConfirmDeleteTagTitle: "Are you sure to delete this tag?",
    hasChaseActive: "On Chase",
    hasBotActive: "With Cheetabot",
    lookForAStartMessageToBeginThisConversation: "Look for a start message to start this conversation.",
    clickOAStartMessageToSelectIt: "Click on a start message to select it.",
    sendMessage: "Send message",
    noTemplatesForSearch:
        "You don't have start messages that fit your search query, <strong>click here if you need to create a new one</strong>.",
    clickOAQuickTextToSelectIt: "Click on a quick reply to select it.",
    noQuickTextsForSearch:
        "You don't have quick replies that fit your search query, <strong>click here if you need to create a new one</strong>.",
    noQuickTexts: "You don't have quick replies created, <strong>click here to create quick replies</strong>.",
    error63018: "Your WhatsApp number has exceeded the rate limit imposed by WhatsApp.",
    error63038: "Your WhatsApp number has exceeded the maximum amount of messages per 24 hours imposed by WhatsApp.",
    whatsAppLimitLink: "For more information, see WhatsApp Rate Limiting.",
    messageLimitWarning: "You are reaching WhatsApp's daily conversations limit!",
    messageLimitReached: "You have reached WhatsApp's daily conversations limit!",
    messageLimitDesc:
        "WhatsApp imposes a 1000 conversations daily limit, if you reach that amount you won't be able to start conversations until the next day.",
    audioFormatIncompatible: "This browser is not compatible with the audio format, please use another browser.",
    instagramClient: "Instagram Client",
    conversationSource: "Source",
    instagramTimeWindowExp1:
        "Facebook and Instagram APIs limit us, as a message provider, to only respond to messages.",
    instagramTimeWindowExp2:
        "This means you cannot start a conversation with a Facebook or Instagram account. An only if someone sends a direct message to you, a 24-hour window opens up that allows you to freely send messages to them.",
    instagramTimeWindowExp3: "The time window is currently displayed on the message input",
    instagramTimeWindowExp4:
        "After that time window closes, you won't be able to send messages to that Instagram or Facebook account until they message you again.",
    youNeedToWaitForThisClientToWriteFirst:
        "You can only send messages up to 24-hours after this client has writen to you.",
    youCantSendMultiMsgToInstagramClients:
        "You can't send messages to multiple Instagram or Facebook clients at a time.",
    instagramStoryMention: "See story",
    instagramStoryReply: "Story reply",
    storyContentNotAvailable: "Story content no longer available.",
    instagramStoryMentionText: " has mentioned you in a story.",
    thisClient: "This client",
    thisConversationIsNotAssigned: "This conversation is not assigned to any user.",
    thisConversationIsAssignedTo: "This conversation is assigned to ",
    youWantToSendYourMessageAnyway: "Do you want to send the message anyway?",
    assignThisConversationToYou: "Click here to assign this conversation to you.",
    sendMessageAnyway: "Send message",
    hasNotReadTheLastMessage: " has not read the last message.",
    markAsRead: "Mark as read",
    hasReadAllMessages: " has read all messages.",
    markAsUnread: "Mark as unread",
    youHaveReadAllMessages: "You have read all messages.",
    allMessagesHaveBeenRead: "All messages have been read.",
    youHaveSentTheSameMessageXTimes:
        "You have sent the same message {x} times in a row, would you like to create it as a quick reply?",
    clickToMarkAsRead: "Click to mark as read",
    clickToMarkAsUnread: "Click to mark as unread",
    pauseChase: "Pause this conversation's current chase",
    resumeChase: "Resume this conversation´s current chase",
    thisConversationHasAPausedChase: "This conversation has a paused chase",
    pause: "Pause",
    resume: "Resume",
    sendYourShopifyProducts: "Send your Shopify products to your clients",
    chooseAShopifyProductToSend: "Choose a Shopify product to send:",
    sendProductAsMsg: "Send a message with this product",
    sendACheckoutLink: "Send as checkout link",
    textMessage: "Text Message",
    typeToOpenQuickReplies: "Type / to open quick replies.",
    SMSExplanation1:
        "A SMS has a content size that depends on it's length and whether it contains symbols, urls, emojis, etc.",
    SMSExplanation2: "Depending on the content size of the SMS it will be divided into segments.",
    SMSExplanation3: "Each segment will be sent as an individual message and charged as such.",
    SMSExplanation4: "Additionally, the whole SMS cannot be longer than 320 characters.",
    thisMessageWillBeSentAsXSegments: "This message will be sent in {x} segments.",
    error21610: "This client has unsubscribed to your SMS messages.",
    youHaveReachedTheBottom:
        "No more conversations found. Check your filters or search query if you need to see others.",

    thisMessageExceedsTheCharacterLimit:
        "This message exceeds the character limit. Do you want to send it as multiple messages?",
    good: "Good",
    bad : "Bad",
    newClient: "New Client",
    lastExpGood: "Last. Exp.: Good",
    lastExpBad: "Last. Exp.: Bad",
    daysLastVisit:"{days} days last visit",
    tableSector:"Table-sector",
    numberVisits: "{number_visits} visits",

}

export default messages
