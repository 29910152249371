import { defaultMessageVariables } from "../constants"

// Reemplaza las variables por defecto de los mensajes

export const replaceDefaultMessageVariables = (message, user, client, tenant) => {
    let updateMessage = message,
        data = { user, client, tenant }
    let variables = {}
    let count = 0
    defaultMessageVariables.forEach((v) => {
        let text = ""
        const regex = new RegExp(v.bracket_name, "gi")

        if (data[v.parent] && (data[v.parent][v.prop_name] || v.combined_props)) {
            if (v.combined_props) v.combined_props.forEach((prop) => (text += (data[v.parent][prop] || "") + " "))
            else text = data[v.parent][v.prop_name]

            if (v.alternatives)
                v.alternatives.forEach((alt) => {
                    const regex = new RegExp(alt, "gi")
                    updateMessage = updateMessage.replace(regex, text)
                    variables[++count] = text
                })
        }
        updateMessage = updateMessage.replace(regex, text)
        variables[++count] = text

    })
    return updateMessage
}

export const isADefaultMessageVariable = (variable) => {
    return defaultMessageVariables.some((v) => v.bracket_name === variable || v.alternatives.includes(variable))
}

// Genera ejemplos de las variables para previews de templates

export const getVariableExamples = (message, user, client, tenant) => {
    const examples = [],
        data = { user, client, tenant },
        variables = message.match(/\{{(.+?)\}}/g)

    if (variables) {
        variables.forEach((v) => {
            let example = null
            defaultMessageVariables.forEach((d) => {
                if (v === d.bracket_name) {
                    if (d.combined_props)
                        d.combined_props.forEach((prop) => (example += (data[d.parent][prop] || "") + " "))
                    else example = data[d.parent][d.prop_name]

                    examples.push({ variable: v, example })
                } else if (d.alternatives) {
                    d.alternatives.forEach((alt) => {
                        if (v === alt) {
                            if (d.combined_props)
                                d.combined_props.forEach((prop) => (example += (data[d.parent][prop] || "") + " "))
                            else example = data[d.parent][d.prop_name]

                            examples.push({ variable: v, example })
                        }
                    })
                }
            })
            if (example === null) {
                if (v.includes("name") || v.includes("nombre")) examples.push({ variable: v, example: "Pedro Perez" })
                else if (v.includes("email")) examples.push({ variable: v, example: "Support@Cheetrack.com" })
                else if (v.includes("phone") || v.includes("teléfono"))
                    examples.push({ variable: v, example: "+1 555 555 5555" })
                else if (v.includes("company") || v.includes("compañía"))
                    examples.push({ variable: v, example: "Cheetrack" })
                else if (v.includes("country") || v.includes("país")) examples.push({ variable: v, example: "Chile" })
                else if (v.includes("date") || v.includes("fecha"))
                    examples.push({ variable: v, example: "2021-01-01" })
                else if (v.includes("time") || v.includes("hora")) examples.push({ variable: v, example: "12:00" })
                else if (v.includes("city") || v.includes("ciudad")) examples.push({ variable: v, example: "Santiago" })
                else if (v.includes("price") || v.includes("precio") || v.includes("cost"))
                    examples.push({ variable: v, example: "20 USD" })
                else if (v.includes("product") || v.includes("producto"))
                    examples.push({ variable: v, example: "Laptop" })
                else if (v.includes("greeting") || v.includes("saludo"))
                    examples.push({ variable: v, example: "Hello!" })
                else if (v.includes("color") || v.includes("colour")) examples.push({ variable: v, example: "Red" })
                else examples.push({ variable: v, example: "custom variable" })
            }
        })
    }

    return examples
}
